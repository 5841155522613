var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-block d-md-flex align-stretch"},[_c('v-text-field',{attrs:{"focus":"","label":"Cerca cliente","filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense,"clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"click:clear":_vm.clearClienti},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-btn',{staticClass:"ml-0 ml-md-5 mt-md-0",attrs:{"disabled":!_vm.searchText,"color":"primary","outlined":"","x-large":"","loading":_vm.loading},on:{"click":_vm.search}},[_vm._v("CERCA")])],1)])],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-data-table',{attrs:{"item-class":"caption","items":_vm.clienti,"headers":_vm.tableHeaders,"loading":_vm.loading,"loading-text":"Caricamento..."},scopedSlots:_vm._u([{key:"item.IdCard",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.editCard(item.IdCard)}}},[_vm._v(_vm._s(item.IdCard))])]}},{key:"item.CodFiscale",fn:function(ref){
var item = ref.item;
return [(item.CodFiscale)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.editCliente(item.CodFiscale)}}},[_vm._v(_vm._s(item.CodFiscale))]):_vm._e()]}},{key:"item.CodTelaio",fn:function(ref){
var item = ref.item;
return [(item.CodTelaio)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.editTelaio(item.CodTelaio)}}},[_vm._v(_vm._s(item.CodTelaio))]):_vm._e()]}},{key:"item.Stato",fn:function(ref){
var item = ref.item;
return [(item.Stato == 0)?_c('v-chip',{attrs:{"small":""}},[_vm._v("Non attiva")]):(item.Stato == 1)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("Attiva")]):(item.Stato == 2)?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v("Annullata")]):_c('span',[_vm._v("-")])]}},{key:"item.Attivazione",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("reportShortDate")(item.Attivazione)))])]}}],null,true)})],1)],1),_c('admin-card-edit',{attrs:{"idcard":_vm.currentIdCard},model:{value:(_vm.modalCardVisible),callback:function ($$v) {_vm.modalCardVisible=$$v},expression:"modalCardVisible"}}),_c('admin-cliente-edit',{attrs:{"codfiscale":_vm.currentCodFiscale},model:{value:(_vm.modalClienteVisible),callback:function ($$v) {_vm.modalClienteVisible=$$v},expression:"modalClienteVisible"}}),_c('admin-telaio-edit',{attrs:{"codtelaio":_vm.currentCodTelaio},model:{value:(_vm.modalTelaioVisible),callback:function ($$v) {_vm.modalTelaioVisible=$$v},expression:"modalTelaioVisible"}}),_c('debug-panel',{staticClass:"mt-6",attrs:{"label":"clienti"}},[_vm._v(" "+_vm._s(_vm.clienti)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }