<template>
	<standard-container title="Gestione Card / Clienti / Telai" admin disable-admin-prefix>
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab v-if="$isAuthorizedTo('admin.cardclientitelai')" key="tabCard">Card</v-tab>
				<v-tab v-if="$isAuthorizedTo('admin.cardclientitelai')" key="tabClienti">Clienti</v-tab>
				<v-tab v-if="$isAuthorizedTo('admin.cardclientitelai')" key="tabTelai">Telai</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-tabs-items v-model="currentTab">
			<!-- card attive pers -->
			<v-tab-item v-if="$isAuthorizedTo('admin.cardclientitelai')" key="tabCard" eager class="ma-1">
				<admin-card></admin-card>
			</v-tab-item>
			<v-tab-item v-if="$isAuthorizedTo('admin.cardclientitelai')" key="tabClienti" eager class="ma-1">
				<admin-clienti></admin-clienti>
			</v-tab-item>
			<v-tab-item v-if="$isAuthorizedTo('admin.cardclientitelai')" key="tabTelai" eager class="ma-1">
				<admin-telai></admin-telai>
			</v-tab-item>
		</v-tabs-items>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** ADMIN CARD CLIENTI E TELAI ***************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
import AdminCard from '@/views/Admin/CardClientiTelai/Card'
import AdminTelai from '@/views/Admin/CardClientiTelai/Telaio'
import AdminClienti from '@/views/Admin/CardClientiTelai/Cliente'
export default {
	name: 'AdminCardClientiTelaiPage',
	components: { StandardContainer, AdminCard, AdminTelai, AdminClienti},
	data: () => {
		return {
			currentTab: -1,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>