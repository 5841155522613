<template>
	<v-dialog v-model="formVisible" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs'" transition="scale-transition" max-width="1000" persistent @keydown.esc="close">
		<v-card>
			<v-toolbar dense :color="appSetup.appColor" dark>
				<v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
				<v-toolbar-title>Dettaglio cliente {{ codfiscale }}</v-toolbar-title>
			</v-toolbar>
			<v-card-text>
				<v-row v-if="loading"><v-col class="text-center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-col></v-row>					
				<v-row v-if="currentCliente" class="mt-4">
					<v-col md="6">
						<v-text-field v-model="currentCliente.Codicefiscale" label="Codice fiscale / partita iva" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<v-select v-model="currentCliente.Sesso" :items="sessoList" label="Sesso" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
					</v-col>					
					<v-col v-if="currentCliente.Sesso !== 'D'" md="6"><v-text-field v-model="currentCliente.Nome" label="Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
					<v-col v-if="currentCliente.Sesso !== 'D'" md="6"><v-text-field v-model="currentCliente.Cognome" label="Cognome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
					<v-col v-if="currentCliente.Sesso === 'D'" md="12"><v-text-field v-model="currentCliente.RagSoc" label="Ragione sociale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
					<v-col md="4"><v-select v-model="currentCliente.Tipovia" :items="decodifiche.TipoVie" label="Tipo via" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select></v-col>
					<v-col md="6"><v-text-field v-model="currentCliente.Indirizzo" label="Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
					<v-col md="2"><v-text-field v-model="currentCliente.Civico" label="Civico" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
					<v-col md="3">
						<v-autocomplete v-model="currentCliente.Provincia" label="Provincia" :items="listaProvincie" item-text="NomeProv" item-value="Sigla" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="variazioneProvincia"></v-autocomplete>
					</v-col>
					<v-col md="6">
						<v-autocomplete v-model="currentCliente.Comune" :disabled="!currentCliente.Provincia" label="Comune" :items="listaComuni" item-text="NomeComune" item-value="NomeComune" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
					</v-col>
					<v-col md="3"><v-text-field v-model="currentCliente.Cap" label="Cap" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field></v-col>
					<v-col md="4"><v-text-field v-model="currentCliente.TelFisso" label="Telefono fisso" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field></v-col>
					<v-col md="4"><v-text-field v-model="currentCliente.TelMobile" label="Telefono mobile" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field></v-col>
					<v-col md="4"><v-text-field v-model="currentCliente.Email" label="Email" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field></v-col>
					<v-col v-if="currentCliente.Sesso !== 'D'" md="3">
						<hr-date-picker v-model="currentCliente.DataNascita" :show-current="false" :picker-date="currentCliente.DataNascita" label="Data di nascita" persistent-hint clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="variazioneDataDiNascita"></hr-date-picker>
					</v-col>
					<v-col v-if="currentCliente.Sesso !== 'D'" md="3">
						<v-autocomplete v-model="currentCliente.ProvinciaNas" label="Provincia di nascita" :items="listaProvincieNascita" item-text="NomeProv" item-value="Sigla" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="variazioneProvinciaNascita"></v-autocomplete>
					</v-col>
					<v-col v-if="currentCliente.Sesso !== 'D'" md="6">
						<v-autocomplete v-if="currentCliente.ProvinciaNas!='EE'" v-model="currentCliente.ComuneNas" :disabled="!currentCliente.ProvinciaNas" label="Comune di nascita" :items="listaComuniNascita" item-text="NomeComune" item-value="NomeComune" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
						<v-autocomplete v-if="currentCliente.ProvinciaNas=='EE'" v-model="currentCliente.ComuneNas" label="Stato estero" :items="listaStatiNascita" item-text="NomeStato" item-value="NomeStato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
					</v-col>
					<v-col md="6">
						<v-radio-group v-model="currentCliente.Smartphone" row label="Smartphone?">
							<v-radio value="SI" label="Si"></v-radio> 
							<v-radio value="NO" label="No"></v-radio> 
							<v-radio value="" label="Non risponde"></v-radio> 
						</v-radio-group>
					</v-col>
					<v-col md="6">
						<v-select v-model="currentCliente.PhoneOs" :items="decodifiche.PhoneOs" label="Tipologia di smartphone" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
					</v-col>	
					<v-col md="4"><v-checkbox v-model="currentCliente.Privacy" label="Privacy consenso Servizio" value="S" class="ma-0"></v-checkbox></v-col>				
					<v-col md="4"><v-checkbox v-model="currentCliente.Privacy2" label="Privacy consenso Marketing" value="S" class="ma-0"></v-checkbox></v-col>				
					<v-col md="4"><v-checkbox v-model="currentCliente.Privacy3" label="Privacy consenso profilazione" value="S" class="ma-0"></v-checkbox></v-col>				
				</v-row>
				<errors-container :errors="errors"></errors-container>
				<debug-panel label="currentCliente">
					{{ currentCliente }}
				</debug-panel>
			</v-card-text>
			<v-card-actions class="d-flex justify-space-between">
				<v-btn class="ma-6" large outlined @click="close">Chiudi</v-btn>
				<v-btn class="ma-6" large outlined color="primary" :loading="loading" @click="save">Salva</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>	
</template>

<script>
/**********************************************************************************************
**** MODALE DI EDIT PER I TELAI ****************************************************************************
***********************************************************************************************
* 
*/
import moment from 'moment'
import DebugPanel from '@/components/debug-panel'
import ErrorsContainer from '@/components/errors-container'
import HrDatePicker from '@/components/hr-date-picker'
//
export default {
	name: 'AdminCardTelaioComponent',
	components: { DebugPanel, ErrorsContainer, HrDatePicker  },
	props: {
		// determina la visibilità della modale
		value: {
			type: Boolean,
			required: true,
			default: () => {return false}
		},
		// codice fiscale del cliente da editare
		codfiscale: {
			type: String,
			required: true,
			default: () => {return ''}
		}
	},
	data: () => {
		return {
			loading: false,
			formVisible: false,
			listaProvincie: [],
			listaProvincieNascita: [],
			listaComuni: [],
			listaComuniNascita: [],
			listaStati: [],
			listaStatiNascita: [],
			errors: [],
			sessoList: [ {text:'Ditta', value:'D'}, {text:'Maschio', value:'M'}, {text:'Femmina ', value:'F'} ],
			currentCliente: {},
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		/*
		// se la provincia corrente è impostata in EE allora il cliente è nato all'estero
		isEstero: {
			get: function() {
				return this.currentCliente.Provincia == 'EE'
			},
			set: function(newValue) {
				this.currentCliente.Provincia = newValue ? 'EE' : ''
				this.currentCliente.Comune = ''
			}
		},
		*/
	},
	watch:{
		// intercetta apertura e chiusura della modale
		'value'(to, from) {
			if(to == true && from == false) {
				// apertura
				this.formVisible = true
				this.load()
				this.caricaProvince()
				this.caricaStati()
			} else {
				// chiusura 
				this.formVisible = false
			}
		}
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},		
	methods: {
		close() {
			this.$emit('input', false)
		},
		// carica i dati del cliente
		load() {
			this.currentCliente = {}
			this.modelli = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/clienti/get', value: {SearchValue: this.codfiscale}}).then(result => {
				this.currentCliente = result.Cliente
				this.currentCliente.OldCodiceFiscale = this.currentCliente.Codicefiscale
				this.caricaComuni()
				this.caricaComuniNascita()
				this.caricaStatiNascita()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		save() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/clienti/set', value: this.currentCliente}).then(result => {
				this.close()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// -----------------------------------------------------------------------------------------------------------
		// carica tutte le province (rispetto allo step precedente qui carica la lista delle attuali -> no, servono anche le vecchie, vedi #175 )
		caricaProvince() {
			
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaProvinceAttuali'}).then(result => {
				this.listaProvincie = result
				if(!result)
					this.errors.push({text: 'Errore: non è stato possibile scaricare l\'elenco delle provincie (attuali)'})
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				
			})


			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaProvince'}).then(result => {
				this.listaProvincieNascita = result
				if(!result)
					this.errors.push({text: 'Errore: non è stato possibile scaricare l\'elenco delle provincie'})
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				
			})

		},
		// carica tutti i comuni della provincia selezionata (usa la data corrente come filtro)
		caricaComuni() {
			if(this.currentCliente.Provincia) {
				
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaComuni', value: {SiglaProvincia: this.currentCliente.Provincia, DataFiltro: moment().format('YYYY-MM-DD')}}).then(result => {
					this.listaComuni = result
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
				
				})
			}
		},		
	
		// carica tutti gli stati attuali (usa la data corrente come filtro)
		caricaStati() {
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaStati', value: {DataFiltro: moment().format('YYYY-MM-DD')}}).then(result => {
				this.listaStati = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				
			})
		},		


		// carica tutti i comuni della provincia di nascita selezionata (usa la data di nascita come filtro)
		caricaComuniNascita() {
			if(this.currentCliente.ProvinciaNas) {
				let dataNascita = this.currentCliente.DataNascita
				if(!dataNascita) dataNascita = new Date()
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaComuni', value: {SiglaProvincia: this.currentCliente.ProvinciaNas, DataFiltro: moment(dataNascita).format('YYYY-MM-DD')}}).then(result => {
					this.listaComuniNascita = result
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
				
				})
			}
		},	

		// carica tutti gli stati esistenti al momento della nascita del cliente
		caricaStatiNascita() {
			let dataNascita = this.currentCliente.DataNascita
			if(!dataNascita) dataNascita = new Date()
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaStati', value: {DataFiltro: moment(dataNascita).format('YYYY-MM-DD')}}).then(result => {
				this.listaStatiNascita = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				
			})
		},	
		variazioneDataDiNascita() {
			this.caricaStatiNascita()
		},
		// chiamato dalla variazione del radio-button dello stato di residenza, scatena il reload degli stati o dei comuni
		variazioneStatoResidenza() {
			this.caricaComuni()
		},
		// alla variazione della provincia ricarica i relativi comuni
		variazioneProvincia() {
			this.caricaComuni()
		},		
		// alla variazione della provincia di nascita ricarica i relativi comuni
		variazioneProvinciaNascita() {
			this.caricaComuniNascita()
		},		
	},
}
</script>

<style scoped lang="less">

</style>