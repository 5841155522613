<template>
	<v-dialog v-model="formVisible" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs'" transition="scale-transition" max-width="1000" persistent @keydown.esc="close">
		<v-card>
			<v-toolbar dense :color="appSetup.appColor" dark>
				<v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
				<v-toolbar-title>Dettaglio telaio {{ codtelaio }}</v-toolbar-title>
			</v-toolbar>
			<v-card-text>
				<v-row v-if="loading"><v-col class="text-center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-col></v-row>					
				<v-row v-if="currentTelaio" class="mt-4">
					<v-col md="6">
						<v-text-field v-model="currentTelaio.CodTelaio" label="Codice telaio" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<v-text-field v-model="currentTelaio.Targa" label="Targa" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<hr-date-picker v-model="currentTelaio.DataImmatricolazione" :show-current="false" :picker-date="currentTelaio.DataImmatricolazione" label="Data immatricolazione" persistent-hint clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></hr-date-picker>
					</v-col>
					<v-col md="6">
						<v-select v-model="currentTelaio.Modello" item-text="Modello" item-value="Modello" label="Modello" :items="modelli" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<v-text-field v-model="currentTelaio.CodKuba" label="Codifica KUBA" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>					
					<v-col md="6">
						<v-select v-model="currentTelaio.Gpl" :items="gplList" label="Gpl" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
					</v-col>					
				</v-row>
				<errors-container :errors="errors"></errors-container>
				<debug-panel label="currentTelaio">
					{{ currentTelaio }}
				</debug-panel>
			</v-card-text>
			<v-card-actions class="d-flex justify-space-between">
				<v-btn class="ma-6" large outlined @click="close">Chiudi</v-btn>
				<v-btn class="ma-6" large outlined color="primary" :loading="loading" @click="save">Salva</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>	
</template>

<script>
/**********************************************************************************************
**** MODALE DI EDIT PER I TELAI ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import ErrorsContainer from '@/components/errors-container'
import HrDatePicker from '@/components/hr-date-picker'
//
export default {
	name: 'AdminCardTelaioComponent',
	components: { DebugPanel, ErrorsContainer, HrDatePicker,  },
	props: {
		// determina la visibilità della modale
		value: {
			type: Boolean,
			required: true,
			default: () => {return false}
		},
		// id della card da editare
		codtelaio: {
			type: String,
			required: true,
			default: () => {return ''}
		}
	},
	data: () => {
		return {
			loading: false,
			formVisible: false,
			errors: [],
			modelli: [],
			gplList: [ {text:'Si', value:'S'}, {text:'No', value:'N'} ],
			currentTelaio: {},
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	watch:{
		// intercetta apertura e chiusura della modale
		'value'(to, from) {
			if(to == true && from == false) {
				// apertura
				this.formVisible = true
				this.load()
			} else {
				// chiusura 
				this.formVisible = false
			}
		}
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},		
	methods: {
		close() {
			this.$emit('input', false)
		},
		// carica i dati del telaio
		load() {
			this.currentTelaio = {}
			this.modelli = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/telai/get', value: {SearchValue: this.codtelaio}}).then(result => {
				this.currentTelaio = result.Telaio
				this.modelli = result.Modelli
				this.currentTelaio.OldCodTelaio = this.currentTelaio.CodTelaio
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		save() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/telai/set', value: this.currentTelaio}).then(result => {
				this.close()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		}
	},
}
</script>

<style scoped lang="less">

</style>