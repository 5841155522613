<template>
	<div>
		<v-row class="mt-2">
			<v-col md="6">
				<div class="d-block d-md-flex align-stretch">
					<v-text-field v-model="searchText" focus label="Cerca card" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" clearable @keyup.enter="search" @click:clear="clearCard" />
					<v-btn :disabled="!searchText" color="primary" outlined class="ml-0 ml-md-5 mt-md-0" x-large :loading="loading" @click="search">CERCA</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>
				<v-data-table 
					item-class="caption"
					:items="cards"
					:headers="tableHeaders"
					:loading="loading"
					loading-text="Caricamento..."
				>
					<template v-slot:[`item.IdCard`]="{ item }">
						<v-btn small @click="editCard(item.IdCard)">{{ item.IdCard }}</v-btn>
					</template>
					<template v-slot:[`item.CodFiscale`]="{ item }">
						<v-btn v-if="item.CodFiscale" small @click="editCliente(item.CodFiscale)">{{ item.CodFiscale }}</v-btn>
					</template>
					<template v-slot:[`item.CodTelaio`]="{ item }">
						<v-btn v-if="item.CodTelaio" small @click="editTelaio(item.CodTelaio)">{{ item.CodTelaio }}</v-btn>
					</template>
					<template v-slot:[`item.Stato`]="{ item }">
						<v-chip small v-if="item.Stato == 0">Non attiva</v-chip>
						<v-chip small color="success" v-else-if="item.Stato == 1">Attiva</v-chip>
						<v-chip small color="error" v-else-if="item.Stato == 2">Annullata</v-chip>
						<span v-else>-</span>
					</template>
					<template v-slot:[`item.Attivazione`]="{ item }">
						<span class="text-no-wrap">{{ item.Attivazione | reportShortDate }}</span>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<admin-card-edit v-model="modalCardVisible" :idcard="currentIdCard"></admin-card-edit>
		<admin-cliente-edit v-model="modalClienteVisible" :codfiscale="currentCodFiscale"></admin-cliente-edit>
		<admin-telaio-edit v-model="modalTelaioVisible" :codtelaio="currentCodTelaio"></admin-telaio-edit>


		<debug-panel label="cards" class="mt-6">
			{{ cards }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** ADMIN CARDS ******************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import AdminCardEdit from '@/views/Admin/CardClientiTelai/CardEditComponent'
import AdminClienteEdit from '@/views/Admin/CardClientiTelai/ClienteEditComponent'
import AdminTelaioEdit from '@/views/Admin/CardClientiTelai/TelaioEditComponent'
//
export default {
	name: 'AdminCards',
	components: { DebugPanel, AdminCardEdit, AdminClienteEdit, AdminTelaioEdit},
	props: {

	},
	data: () => {
		return {
			loading: false,
			searchText: '',
			tableHeaders: [
				{ text: 'ID-card' 			, value: 'IdCard' 			},
				{ text: 'Cod.Fiscale/P.IVA' , value: 'CodFiscale' 			},
				{ text: 'Cod. Telaio' 		, value: 'CodTelaio' 		},
				{ text: 'Stato' 			, value: 'Stato' 		},
				{ text: 'Data Att.	' 		, value: 'Attivazione' 			},
				// ID-Card	Cod.Fiscale/P.IVA	Cod. Telaio	Stato	Data Att.		
			],
			cards: [],
			errors: [],
			modalCardVisible: false,
			currentIdCard: '',
			modalClienteVisible: false,
			currentCodFiscale: '',
			modalTelaioVisible: false,
			currentCodTelaio: '',
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	watch:{
		// intercetta chiusura delle modali e ricarica
		'modalCardVisible'(to, from) {
			if(to == false && from == true) {
				this.search()
			}
		},
		'modalTelaioVisible'(to, from) {
			if(to == false && from == true) {
				this.search()
			}
		},
		'modalClienteVisible'(to, from) {
			if(to == false && from == true) {
				this.search()
			}
		},
	},	
	created: function () {
		
	},
	methods: {
		search() {
			if(this.searchText) {
				this.cards = []
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/admin/cards/search', value: {SearchValue: this.searchText}}).then(result => {
					this.cards = result ? result : []
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			}
		},
		clearCard(){
			this.cards = []
		},	
		editCard(idCard) {
			this.modalCardVisible = true
			this.currentIdCard = idCard
		},
		editCliente(codFiscale) {
			this.modalClienteVisible = true
			this.currentCodFiscale = codFiscale

		},
		editTelaio(codTelaio) {
			this.modalTelaioVisible = true
			this.currentCodTelaio = codTelaio

		},
	},
}
</script>

<style scoped lang="less">

</style>