<template>
	<v-dialog v-model="formVisible" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs'" transition="scale-transition" max-width="1000" persistent @keydown.esc="close">
		<v-card>
			<v-toolbar dense :color="appSetup.appColor" dark>
				<v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
				<v-toolbar-title>Dettaglio card {{ idcard }}</v-toolbar-title>
			</v-toolbar>
			<v-card-text>
				<v-row v-if="loading"><v-col class="text-center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-col></v-row>					
				<v-row v-if="currentCard" class="mt-4">
					<v-col md="6">
						<v-text-field v-model="currentCard.IdCard" label="Id card" disabled :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<v-select v-model="currentCard.Stato" label="Stato" :items="itemsStato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<v-text-field v-model="currentCard.OrgAttivazione" label="Org. attivazione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<hr-date-picker v-model="currentCard.Attivazione" :show-current="false" :picker-date="currentCard.Attivazione" label="Data attivazione" persistent-hint clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></hr-date-picker>
					</v-col>
					<v-col md="6">
						<v-text-field v-model="currentCard.OrgCessazione" label="Org. cessazione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
					</v-col>
					<v-col md="6">
						<hr-date-picker v-model="currentCard.Cessazione" :show-current="false" :picker-date="currentCard.Attivazione" label="Data cessazione" persistent-hint clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></hr-date-picker>
					</v-col>
				</v-row>
				<errors-container :errors="errors"></errors-container>
				<debug-panel label="currentCard">
					{{ currentCard }}
				</debug-panel>
			</v-card-text>
			<v-card-actions class="d-flex justify-space-between">
				<v-btn class="ma-6" large outlined @click="close">Chiudi</v-btn>
				<v-btn class="ma-6" large outlined color="primary" :loading="loading" @click="save">Salva</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>	
</template>

<script>
/**********************************************************************************************
**** MODALE DI EDIT PER LE CARD ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import ErrorsContainer from '@/components/errors-container'
import HrDatePicker from '@/components/hr-date-picker'
//
export default {
	name: 'AdminCardEditComponent',
	components: { DebugPanel, ErrorsContainer, HrDatePicker },
	props: {
		// determina la visibilità della modale
		value: {
			type: Boolean,
			required: true,
			default: () => {return false}
		},
		// codice del telaio da editare
		idcard: {
			type: String,
			required: true,
			default: () => {return ''}
		}
	},
	data: () => {
		return {
			loading: false,
			formVisible: false,
			itemsStato: [{text: 'Non attiva', value:0},{text: 'Attiva', value:1},{text: 'Annullata', value:2} ],
			errors: [],
			currentCard: {},
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
	},
	watch:{
		// intercetta apertura e chiusura della modale
		'value'(to, from) {
			if(to == true && from == false) {
				// apertura
				this.formVisible = true
				this.load()
			} else {
				// chiusura 
				this.formVisible = false
			}
		}
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},		
	methods: {
		close() {
			this.$emit('input', false)
		},
		// carica i dati della card
		load() {
			this.currentCard = {}
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/cards/search', value: {SearchValue: this.idcard}}).then(result => {
				this.currentCard = result[0]
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		save() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/cards/set', value: this.currentCard}).then(result => {
				this.close()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		}
	},
}
</script>

<style scoped lang="less">

</style>